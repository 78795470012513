.loading {
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    animation: loading-logo-spin infinite 2s linear;
    height: 30vmin;
  }
}
@keyframes loading-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
