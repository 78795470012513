@import '../Variables.scss';

.products {
  flex-flow: row wrap;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: $tablet-breakpoint) {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
}

.product {
  width: 100%;
  max-height: 160px;
  padding: 24px;
  border: 1px dotted $color2;

  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  margin-bottom: 25px;
  position: relative;
  &:nth-child(odd) {
  }
  &:nth-child(1) {
  }
  &:nth-child(2) {
  }
  & + .product {
  }

  > div:nth-of-type(1) {
    margin-right: 16px;
    width: 110px;
    height: 110px;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
  }
  > div:nth-of-type(2) {
    word-break: break-word;
    width: calc(100% - 105px - 24px);
    font-weight: 600;
    font-size: 18px;
    max-height: 42px;
    overflow: hidden;
  }
  > div:nth-of-type(3) {
    width: calc(100% - 105px - 24px);
    max-height: 18px;
    overflow: hidden;
  }
  > div:nth-of-type(4) {
    width: calc(100% - 105px - 24px);
    color: $color4;
    font-size: 20px;
    font-weight: 600;
    text-align: right;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    align-items: flex-end;
    position: absolute;
    right: 24px;
    bottom: 24px;
    > span:first-child {
      color: $color5;
      font-weight: normal;
      font-size: 14px;
      margin-right: 6px;
      margin-bottom: 3px;
    }
    > span:last-child {
    }
  }
  > div:nth-of-type(5) {
  }
  @media screen and (min-width: $tablet-breakpoint) {
    max-width: calc(50% - 20px);
    margin: 10px;
    > div:nth-of-type(1) {
    }
    > div:nth-of-type(2) {
    }
    > div:nth-of-type(3) {
    }
  }
}

.products-filters {
  display: flex;
  margin-top: 24px;
}

.products-summary {
  display: flex;
  justify-content: center;
  color: #87ba8f;
  span {
    color: $color3;
  }
}
.products-filters-categories {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
  display: flex;
  flex-flow: column;
  > span:nth-child(1) {
    font-weight: 600;
    color: $color4;
    font-size: 18px;
    margin-bottom: 6px;
  }
  > span:nth-child(2) {
    padding: 9px;
    border: 1px dotted $color2;
    > span {
      padding: 4px 10px;
      border-radius: 12px;
      margin: 2px 3px;
      display: inline-block;
      cursor: pointer;
      > span {
        &:first-child {
        }
        &:last-child {
          margin-left: 4px;
          color: $color4;
        }
      }
      // &:hover {
      //   background: $color2;
      // }
      &.selected {
        background: $color5;
        > span {
          color: $color1;
        }
      }
    }
  }
}
