@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);

html {
  min-height: 100%;
  height: 100%; }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  height: 100%; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

* {
  box-sizing: border-box; }

#root {
  min-height: 100%;
  height: 100%; }

.App {
  min-height: 100%;
  height: 100%;
  max-width: 1100px;
  width: 100%;
  margin: auto;
  position: relative; }

.App-body {
  padding-bottom: 36px;
  padding-top: 48px;
  padding: 20px; }

.App-logo {
  height: 14vmin; }

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 14px 0; }

.products-summary {
  display: block; }

.products {
  flex-flow: row wrap;
  display: flex;
  margin-left: auto;
  margin-right: auto; }
  @media screen and (min-width: 700px) {
    .products {
      width: calc(100% + 20px);
      margin-left: -10px; } }

.product {
  width: 100%;
  max-height: 160px;
  padding: 24px;
  border: 1px dotted #dae994;
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  margin-bottom: 25px;
  position: relative; }
  .product > div:nth-of-type(1) {
    margin-right: 16px;
    width: 110px;
    height: 110px;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer; }
  .product > div:nth-of-type(2) {
    word-break: break-word;
    width: calc(100% - 105px - 24px);
    font-weight: 600;
    font-size: 18px;
    max-height: 42px;
    overflow: hidden; }
  .product > div:nth-of-type(3) {
    width: calc(100% - 105px - 24px);
    max-height: 18px;
    overflow: hidden; }
  .product > div:nth-of-type(4) {
    width: calc(100% - 105px - 24px);
    color: #87ba8f;
    font-size: 20px;
    font-weight: 600;
    text-align: right;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    align-items: flex-end;
    position: absolute;
    right: 24px;
    bottom: 24px; }
    .product > div:nth-of-type(4) > span:first-child {
      color: #ff657e;
      font-weight: normal;
      font-size: 14px;
      margin-right: 6px;
      margin-bottom: 3px; }
  @media screen and (min-width: 700px) {
    .product {
      max-width: calc(50% - 20px);
      margin: 10px; } }

.products-filters {
  display: flex;
  margin-top: 24px; }

.products-summary {
  display: flex;
  justify-content: center;
  color: #87ba8f; }
  .products-summary span {
    color: #ff999a; }

.products-filters-categories {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
  display: flex;
  flex-flow: column; }
  .products-filters-categories > span:nth-child(1) {
    font-weight: 600;
    color: #87ba8f;
    font-size: 18px;
    margin-bottom: 6px; }
  .products-filters-categories > span:nth-child(2) {
    padding: 9px;
    border: 1px dotted #dae994; }
    .products-filters-categories > span:nth-child(2) > span {
      padding: 4px 10px;
      border-radius: 12px;
      margin: 2px 3px;
      display: inline-block;
      cursor: pointer; }
      .products-filters-categories > span:nth-child(2) > span > span:last-child {
        margin-left: 4px;
        color: #87ba8f; }
      .products-filters-categories > span:nth-child(2) > span.selected {
        background: #ff657e; }
        .products-filters-categories > span:nth-child(2) > span.selected > span {
          color: #fefffb; }

.loading {
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center; }
  .loading img {
    -webkit-animation: loading-logo-spin infinite 2s linear;
            animation: loading-logo-spin infinite 2s linear;
    height: 30vmin; }

@-webkit-keyframes loading-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes loading-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

