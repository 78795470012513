@import './Variables.scss';
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700,900');

html {
  min-height: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}

#root {
  min-height: 100%;
  height: 100%;
}

.App {
  min-height: 100%;
  height: 100%;
  max-width: $max-width;
  width: 100%;
  margin: auto;
  position: relative;
}
.App-body {
  padding-bottom: 36px;
  padding-top: 48px;
  padding: 20px;
}

.App-logo {
  height: 14vmin;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 14px 0;
}

.products-summary {
  display: block;
}
